.predictor-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
}


.predictor-container h1 {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.predictor-container h2 {
  font-size: 1.8rem;
  color: #444;
  margin-top: 30px;
}

.predictor-container p {
  font-size: 1rem;
  color: #555;
}

.predictor-container ul {
  list-style-type: none;
  padding: 0;
}

.predictor-container li {
  font-size: 1rem;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  color: #555;
}

.predictor-container .correct-prediction {
  border: 2px solid darkgreen;
  background-color: lightgreen;
}

.predictor-container .incorrect-prediction {
  border: 2px solid darkred;
  background-color: lightcoral;
}

.predictor-container .loading,
.predictor-container .error {
  text-align: center;
  font-size: 1.2rem;
  color: #d9534f;
}

.predictor-container .date {
  text-align: center;
  font-size: 1.1rem;
  color: #888;
  margin-bottom: 10px;
}

.predictor-container .prediction {
  font-weight: bold;
  color: #31708f;
}

.predictor-container .actual {
  font-weight: bold;
  color: #d9534f;
}

.predictor-container button {
  margin: 5px;
  padding: 8px 12px;
  font-size: 1rem;
  background-color: #31708f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.predictor-container button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}