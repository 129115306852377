.section {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
}

.section h2 {
    margin: 12px;
}

.project-content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.project-item {
    display: flex;
    width: 100%;
    min-height: 400px;
    border: 1px solid black;
    padding: 15px;
    margin-bottom: 20px;
    box-sizing: border-box;
}

/* Left side (25%) */
.project-left {
    width: 25%;
}

.project-info {
    min-height: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-name {
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px;
}

.project-logo {
    width: 125px;
    height: 125px;
    margin-bottom: 15px;
    clip-path: inset(9px 0 9px 0);
}

.project-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
}

.project-links a {
    text-decoration: none;
}

.project-dates {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.project-dates img, .project-link img {
    padding-right: 5px;
}

.project-tech-stack {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tech-stack-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.tech-logo {
    width: 40px;
    height: 40px;
    object-fit: contain; 
    object-position: center; 
    background-color: transparent;
}

/* Right side (75%) */
.project-right {
    width: 75%;
    padding-left: 20px;
}

.project-description {
    font-size: 16px;
    line-height: 1.5;
}

.link-logo {
    width: 24px;
    height: 24px;
}

.project-link {
    display: flex;
}

.project-list {
    width: 100%;
    display: flex;
    justify-content: center;
}

.project-list p {
    font-size: 1.3em;
    margin: 5px;
}

.project-list p:hover {
    cursor: pointer;
}

.project-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.project-navigation button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.project-navigation button:hover {
    background-color: #0056b3;
}

@media (max-width: 1000px) {
}