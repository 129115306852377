body {
  background-color: #f5f5f5;
  color: #333;
  transition: background-color 0.5s ease, color 0.5s ease;
}

body.dark-theme {
  background-color: #1c1c1e; 
  color: #e0e0e0; 
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
}

.header-left .logo {
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: inherit;
}

.header-right .nav-list {
  display: flex;
  gap: 20px;
  list-style: none;
  margin: 0;
}

.header-right .nav-list li a {
  text-decoration: none;
  font-size: 18px;
  color: inherit; 
  transition: color 0.5s;
}

a {
  color: inherit;
}

.header-right .nav-list li a:hover {
  color: #007bff;
}

.dark-mode {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
body.dark-theme .header-right .nav-list li a:hover {
  color: #66b2ff; 
}

.light-icon {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(200%) contrast(200%);
}

@media (max-width: 1000px) {
  .header {
    padding: 20px;
    max-width: 100%;
  }
}
