.section {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    box-sizing: border-box;
}

.section h1 {
    margin-bottom: 10px;
    font-size: 36px;
}

.section hr {
    width: 100%;
    margin: 10px auto;
    border: 0;
    border-top: 2px solid #333;
}

.about-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
}

.about-picture {
    flex-basis: 33.33%;
    text-align: center;
}

.about-picture .profile {
    width: 90%;
    height: 90%;
    border-radius: 50%;
}

.about-links {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.linkedin-button,
.resume-button {
    text-decoration: none;
    color: inherit;
}

.resume-button {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.resume-button .resume-icon {
    width: 50px;
}

.about-info {
    flex-basis: 66.66%;
    padding-left: 20px;
}

.about-info p {
    font-size: 18px;
    line-height: 1.8;
}

@media (max-width: 1000px) {
    .section {
        padding: 20px;
        max-width: 100%;
    }
}